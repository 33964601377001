var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-vehicle" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "字典名称：", prop: "dictValue" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入字典名称" },
                model: {
                  value: _vm.addForm.dictValue,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "dictValue",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.dictValue"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "字典编码：", prop: "dictCode" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.isEdit, placeholder: "请输入字典编码" },
                model: {
                  value: _vm.addForm.dictCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "dictCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.dictCode"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }