<template>
  <div class="data-dictionary" ref="dataDictionary">
    <div class="left">
      <p></p>
      <p class="search">
        <el-button
          size="small"
          type="primary"
          @click="addDict"
          v-if="$store.state.menu.nowMenuList.indexOf('新增字典') >= 0"
          >新增字典</el-button
        >
        <el-input
          placeholder="字典名称"
          v-model.trim="keyword"
          class="input-with-select"
        >
          <el-button
            slot="append"
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="searchDictionary"
          ></el-button>
        </el-input>
      </p>
      <div class="dictionary-list">
        <ul ref="dictionaryList">
          <li
            v-for="(dictionary, index) in dictionaryList"
            :class="{ active: index == 0 }"
            :key="dictionary.id"
            @click="onDictionaryClick(dictionary, $event)"
            @dblclick="onDictDbClick(dictionary, $event)"
          >
            {{ dictionary.dictValue }}({{ dictionary.dictCode }})
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <div class="form-area el-inline-form-dict" ref="formArea">
        <el-button
          size="small"
          type="primary"
          :disabled="!form.parentCode"
          @click="addDictValue"
          v-if="$store.state.menu.nowMenuList.indexOf('新增字典值') >= 0"
          >新增字典值</el-button
        >
        <el-form
          ref="form"
          :model="form"
          label-width="60px"
          label-position="left"
          class="input-with-select"
        >
          <el-form-item label="值检索：">
            <el-input placeholder="字典值" v-model.trim="form.dictValue">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="onSearch()"
              :loading="searchLoading"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div style="width: 100%">
        <el-table :data="tableData" border :height="tableHeight">
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column prop="dictCode" label="编码"> </el-table-column>
          <el-table-column prop="dictValue" label="值"> </el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>

          <el-table-column prop="disable" label="状态">
            <template slot-scope="scope">
              {{ scope.row.disable === 0 ? "启用" : "禁用" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="updateManName"
            label="最后更新人"
          ></el-table-column>
          <el-table-column
            prop="updateTime"
            label="最后更新时间"
          ></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="onUpdateDictionary(scope.row)"
                type="text"
                size="small"
                v-if="
                  $store.state.menu.nowMenuList.indexOf('编辑') >= 0 &&
                  scope.row.disable === 0
                "
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                :sort="scope.row.disable === 0 ? 'danger' : 'primary'"
                @click="onDisableDictionary(scope.row)"
                v-if="
                  scope.row.disable === 0
                    ? $store.state.menu.nowMenuList.indexOf('禁用') >= 0
                    : $store.state.menu.nowMenuList.indexOf('启用') >= 0
                "
                >{{ scope.row.disable === 0 ? "禁用" : "启用" }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" ref="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="form.pageSize"
            :current-page="form.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增字典 -->
    <el-dialog
      :title="title"
      v-if="dictVisible"
      :visible.sync="dictVisible"
      width="30%"
    >
      <add-dict ref="addDict" :item="dictItem" @close="onDictClose"></add-dict>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dictVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="onSaveDict()"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!--新增数据字典 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <add-dictionary-value
        ref="addDictValue"
        :item="dictValItem"
        :parentCode="form.parentCode"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></add-dictionary-value>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSaveDictValue()"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import addDict from './components/addDict.vue'
import addDictionaryValue from './components/addDictionaryValue.vue'
import {
  queryDictionaryList,
  queryDictionaryValueList,
  updateDictionaryDisable
} from '@/api/lib/api.js'

export default {
  name: 'dataDictionary',
  components: { addDict, addDictionaryValue },
  data () {
    return {
      dictionaryList: [],
      keyword: '',
      form: {
        parentCode: '',
        dictValue: '',
        currentPage: 1,
        pageSize: 10
      },
      spanArr: [],
      tableData: [],
      total: 0,
      isEditVisible: false,
      tableHeight: 0,
      itemId: null,
      searchLoading: false,
      dialogVisible: false,
      dictVisible: false,
      editLoading: false,
      dictValItem: null,
      dictItem: null,
      title: ''
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.dataDictionary.clientHeight
      const formHeight = this.$refs.formArea.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight - formHeight - 16 - paginationHeight - 16 - 10
    },
    /**查询字典 */
    searchDictionary () {
      this.getDictList(this.keyword)
    },
    /**查询字典值 */
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getDictListByField()
    },
    //获取数据
    getDictListByField () {
      queryDictionaryValueList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
        this.searchLoading = false
      })
    },
    /**获取字典列表 */
    getDictList (name) {
      queryDictionaryList({ dictValue: name }).then((res) => {
        if (res.code === 1000) {
          this.dictionaryList = res.data
          this.form.parentCode = this.dictionaryList[0].dictCode
          this.onSearch()
        } else {
          this.dictionaryList = []
          this.$message.error(res.msg)
        }
      })
    },
    /**选取字典 */
    onDictionaryClick (dict, e) {
      Array.from(this.$refs.dictionaryList.children).forEach((el) => {
        el.classList.remove('active')
      })
      e.target.classList.add('active')
      this.form.parentCode = dict.dictCode
      this.isEditVisible = false
      this.onSearch()
    },

    // 当前页码改变
    onCurrentChange (page) {
      this.searchLoading = true
      this.form.currentPage = page
      this.getDictListByField()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.searchLoading = true
      this.form.pageSize = size
      this.getDictListByField()
    },
    /**新增字典 */
    addDict () {
      this.title = '新增字典'
      this.dictItem = null
      this.dictVisible = true
    },
    /**双击编辑字典 */
    onDictDbClick (dict, e) {
      this.title = '编辑字典'
      this.dictItem = dict
      this.dictVisible = true
    },
    addDictValue () {
      this.title = '新增字典值'
      this.dictValItem = null
      this.dialogVisible = true
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getDictListByField()
        }
      }
      this.editLoading = false
    },
    // 弹窗关闭时的回调
    onDictClose () {
      this.dictVisible = false
      this.getDictList()
    },
    /**编辑 */
    onUpdateDictionary (row) {
      this.title = '编辑字典值'
      this.dictValItem = row
      this.dialogVisible = true
    },
    //  禁用|启用 角色
    onDisableDictionary (row) {
      if (row.disable === 0) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          type: 'warning',
          message: h('p', null, [
            h('span', null, '确定禁用字典 '),
            h('span', { style: 'color: #9154E7' }, row.dictCode),
            h('span', null, ' ？')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            updateDictionaryDisable({ id: row.id, disable: 1 }).then((res) => {
              if (res.code === 1000) {
                this.$message.success('禁用成功')
                row.disable = 1
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              showClose: true,
              message: '已取消禁用'
            })
          })
      } else {
        updateDictionaryDisable({ id: row.id, disable: 0 }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('启用成功')
            row.disable = 0
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    //  保存字典
    onSaveDict (row) {
      this.$refs.addDict.onSave()
    },
    //  保存字典值
    onSaveDictValue () {
      this.$refs.addDictValue.onSave()
    }
  },
  created () {
    this.getDictList()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.data-dictionary {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  @include themify() {
    .input-with-select {
      margin-top: 12px;
    }
    .left {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      width: 187px;
      .search {
        margin: 0 0 16px;
      }
      .dictionary-list {
        flex: 1;
        background-color: themed("n1");
        overflow-y: auto;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          font-size: 14px;
          line-height: 38px;
          height: 100%;
          li {
            padding: 0 16px;
            transition: all 0.2s linear;
            cursor: pointer;
          }
          li.active,
          li:hover {
            background-color: rgba($color: themed("b4"), $alpha: 0.1);
            color: themed("b4");
          }
        }
      }
    }
    .right {
      width: calc(100% - 207px);
      .title {
        // width: 207px;
        margin: 0 0 16px;
      }
    }
  }
}
.theme-project-resourse {
  .data-dictionary {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
